.creator_reg_container {
    display: flex;
    flex-direction: column;
    background-color: var(--form-auth-creator-block-background-color);
    padding: 1rem;

    h4 {
        color: var(--form-auth-title-text-color);
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 700;
        padding: 0;
    }

    h5 {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.25rem;
        padding: 0;
        margin: 0.25rem 0 0.5rem;
        color: var(--form-auth-subtitle-text-color);
    }
}

html.desktop {
    .authentication .new-form-holder {
        flex-grow: 1;
    }

    .authentication_container {
        display: flex;
        flex-direction: column;
    }

    .creator_reg_container {
        flex-direction: row;
        padding: 1.5rem 2.5rem;

        &.login {
            flex-direction: column;

            h5 {
                margin-bottom: 1rem;
            }
        }

        &:not(.login) {
            .creator_reg_cta_container {
                margin-left: auto;
                margin-right: auto;
                flex: 1;
            }

            .creator_reg_cta_container {
                flex: 1;
                align-content: center;
            }

            h5 {
                margin: 0;
            }
        }
    }
}
